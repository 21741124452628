import { onMounted, onUnmounted, Ref, ref } from 'vue'

type OffsetType = { top: number, bottom: number }

export function useContainerScrollProgress(
    element: Ref<HTMLElement | undefined>,
    offset: OffsetType = { top: 0, bottom: 0 }) {

    const progress = ref(0)

    function onScroll() {

        const container = element.value

        if (container === undefined) {

            throw new Error('Invalid reference given.')

        }

        const min = container.offsetTop - window.innerHeight + offset.top
        const max = container.offsetTop + container.offsetHeight - window.innerHeight + offset.bottom
        const value = progress.value = ((window.pageYOffset - min) * 100) / (max - min)

        if (value > 100) {

            progress.value = 100

        } else if (value < 0) {

            progress.value = 0

        } else if (progress.value !== value) {

            progress.value = value

        }

    }

    onMounted(() => onScroll())
    window.addEventListener('scroll', onScroll, false)
    onUnmounted(() => window.removeEventListener('scroll', onScroll, false))

    return {
        progress
    }

}
