

    import { defineComponent } from 'vue'
    // import BlockRevealAnimation from '../components/BlockRevealAnimation.vue'
    import Container from '../components/Container.vue'
    import Column from '../components/Grid/Column.vue'
    import Grid from '../components/Grid/Grid.vue'
    // import Button from '../components/Button.vue'
    import Heading from '../components/Heading.vue'
    import PageHeader from '../components/PageHeader.vue'
    import Paragraph from '../components/Paragraph.vue'
    import Projects from '../components/Projects.vue'
    // import ServiceCover from '../components/ServiceCover.vue'
    import SliderBlock from '../components/SliderBlock.vue'
    import TechnologyWall from '../components/TechnologyWall.vue'
    import ThemeChanger from '../components/ThemeChanger.vue'
    import { useGrid } from '../compositions/UseGrid'
    import { Locale } from '../enums/Locale'
    import { Route } from '../enums/Route'
    import { Theme } from '../enums/Theme'
    import { useCms } from '../compositions/UseCms'
    import { useTheme } from '../compositions/UseTheme'
    import MetaInfo from '../components/MetaInfo.vue'
    import Slider from '../components/Slider.vue'
    import TitleAndList from '../components/TitleAndList.vue'
    import LoopingVideo from '../components/LoopingVideo.vue'
    import { useCarouselSizes } from '../compositions/UseCarouselSizes'

    export default defineComponent({
        name: 'WhatWeDo',
        components: {
            MetaInfo,
            // Button,
            Grid,
            Column,
            Container,
            Paragraph,
            Projects,
            Heading,
            SliderBlock,
            ThemeChanger,
            TechnologyWall,
            PageHeader,
            // BlockRevealAnimation,
            Slider,
            // ServiceCover,
            TitleAndList,
            LoopingVideo
        },
        setup() {

            useTheme().initializeCurrentThemeBasedOnRouteMeta()

            const { whatWeDo } = useCms()
            const { toggleGrid } = useGrid()

            const sizes = useCarouselSizes(whatWeDo.value.design_media_1.assets, true)

            return {
                Route,
                Locale,
                Theme,
                cms: whatWeDo,
                toggleGrid,
                sliderMedia: whatWeDo.value.design_media_1.assets,
                sizes
            }

        }
    })

