import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_TitleAndList = _resolveComponent("TitleAndList")!
  const _component_Container = _resolveComponent("Container")!
  const _component_ThemeChanger = _resolveComponent("ThemeChanger")!
  const _component_Heading = _resolveComponent("Heading")!
  const _component_Column = _resolveComponent("Column")!
  const _component_LoopingVideo = _resolveComponent("LoopingVideo")!
  const _component_Paragraph = _resolveComponent("Paragraph")!
  const _component_Grid = _resolveComponent("Grid")!
  const _component_Slider = _resolveComponent("Slider")!
  const _component_TechnologyWall = _resolveComponent("TechnologyWall")!
  const _component_SliderBlock = _resolveComponent("SliderBlock")!
  const _component_Projects = _resolveComponent("Projects")!
  const _component_MetaInfo = _resolveComponent("MetaInfo")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ThemeChanger, {
      to: _ctx.Theme.LightPink
    }, {
      default: _withCtx(() => [
        _createVNode(_component_PageHeader, {
          class: "mt-32 lg:mt-64",
          header: _ctx.cms.page_header_heading,
          "sub-header": _ctx.cms.page_header_content,
          "header-span": 5
        }, null, 8, ["header", "sub-header"]),
        _createVNode(_component_Container, {
          class: "pt-24 md:pt-48 space-y-24 pb-24",
          fluid: ""
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cms.services, (service, index) => {
              return (_openBlock(), _createBlock(_component_TitleAndList, {
                key: index,
                title: service.title,
                list: service.items
              }, null, 8, ["title", "list"]))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["to"]),
    _createVNode(_component_SliderBlock, null, {
      strategy: _withCtx(() => [
        _createVNode(_component_ThemeChanger, {
          to: _ctx.Theme.LightPink
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Container, {
              class: "pt-12 md:pt-24",
              fluid: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Grid, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_Column, {
                      container: "",
                      push: [ 0, 1 ],
                      span: [ 2, 5 ]
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Column, {
                          span: 4,
                          class: "overflow-hidden"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_Heading, {
                              tag: "h2",
                              class: "z-20",
                              innerHTML: _ctx.cms.ux_strategy_heading_1
                            }, null, 8, ["innerHTML"])
                          ]),
                          _: 1
                        }),
                        (_ctx.cms.ux_strategy_media_1)
                          ? (_openBlock(), _createBlock(_component_Column, {
                              key: 0,
                              class: "mt-12 md:mt-24"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_LoopingVideo, {
                                  source: _ctx.cms.ux_strategy_media_1.desktop_asset.permalink
                                }, null, 8, ["source"])
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        _createVNode(_component_Column, {
                          span: 4,
                          class: "mt-12 md:mt-24"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_Paragraph, {
                              class: "overflow-hidden",
                              innerHTML: _ctx.cms.ux_strategy_content_1
                            }, null, 8, ["innerHTML"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_Container, {
              class: "mt-12 md:mt-24 space-y-12 md:space-y-24",
              fluid: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_TitleAndList, {
                  list: _ctx.cms.ux_strategy_list_1
                }, null, 8, ["list"])
              ]),
              _: 1
            }),
            _createVNode(_component_Container, { fluid: "" }, {
              default: _withCtx(() => [
                _createVNode(_component_Grid, { class: "mt-24" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Column, {
                      span: [ 2, 4 ],
                      push: [ 0, 1 ]
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Heading, {
                          tag: "h2",
                          class: "overflow-hidden",
                          innerHTML: _ctx.cms.ux_strategy_heading_2
                        }, null, 8, ["innerHTML"]),
                        _createVNode(_component_Paragraph, {
                          class: "overflow-hidden",
                          innerHTML: _ctx.cms.ux_strategy_content_2
                        }, null, 8, ["innerHTML"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["to"])
      ]),
      design: _withCtx(() => [
        _createVNode(_component_ThemeChanger, {
          to: _ctx.Theme.LightPink
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Container, {
              fluid: "",
              class: "py-24 md:pt-48 md:pb-24"
            }, {
              default: _withCtx(() => [
                (_ctx.cms.design_heading_1 || _ctx.cms.design_content_1)
                  ? (_openBlock(), _createBlock(_component_Grid, {
                      key: 0,
                      columns: [ 2, 6 ]
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Column, {
                          container: "",
                          push: [ 0, 1 ],
                          span: [ 2, 5 ]
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_Column, { span: 4 }, {
                              default: _withCtx(() => [
                                (_ctx.cms.design_heading_1)
                                  ? (_openBlock(), _createBlock(_component_Heading, {
                                      key: 0,
                                      tag: "h2",
                                      innerHTML: _ctx.cms.design_heading_1
                                    }, null, 8, ["innerHTML"]))
                                  : _createCommentVNode("", true),
                                (_ctx.cms.design_content_1)
                                  ? (_openBlock(), _createBlock(_component_Paragraph, {
                                      key: 1,
                                      innerHTML: _ctx.cms.design_content_1
                                    }, null, 8, ["innerHTML"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_Grid, {
                  collapsed: "",
                  columns: 6,
                  class: "mt-12 md:mt-48"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Slider, {
                      images: _ctx.sliderMedia,
                      sizes: _ctx.sizes
                    }, null, 8, ["images", "sizes"])
                  ]),
                  _: 1
                }),
                (_ctx.cms.design_heading_2 || _ctx.cms.design_content_2)
                  ? (_openBlock(), _createBlock(_component_Grid, {
                      key: 1,
                      columns: [ 2, 6 ],
                      class: "mt-12 md:mt-48"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Column, {
                          push: [ 0, 1 ],
                          span: [ 2, 4 ]
                        }, {
                          default: _withCtx(() => [
                            (_ctx.cms.design_heading_2)
                              ? (_openBlock(), _createBlock(_component_Heading, {
                                  key: 0,
                                  tag: "h2",
                                  class: "overflow-hidden",
                                  innerHTML: _ctx.cms.design_heading_2
                                }, null, 8, ["innerHTML"]))
                              : _createCommentVNode("", true),
                            (_ctx.cms.design_content_2)
                              ? (_openBlock(), _createBlock(_component_Paragraph, {
                                  key: 1,
                                  class: "overflow-hidden text-gray-600",
                                  innerHTML: _ctx.cms.design_content_2
                                }, null, 8, ["innerHTML"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["to"])
      ]),
      technology: _withCtx(() => [
        _createVNode(_component_ThemeChanger, {
          to: _ctx.Theme.Dark
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Container, {
              fluid: "",
              class: "md:pt-24"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Grid, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_Column, {
                      span: [ 2, 4 ],
                      push: [ 0, 1 ]
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Heading, {
                          tag: "h2",
                          class: "overflow-hidden",
                          innerHTML: _ctx.cms.technology_heading_1
                        }, null, 8, ["innerHTML"]),
                        _createVNode(_component_Paragraph, {
                          class: "overflow-hidden text-gray-600",
                          innerHTML: _ctx.cms.technology_content_1
                        }, null, 8, ["innerHTML"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_Grid, { class: "mt-16 md:mt-24" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Column, {
                      span: [ 2, 5 ],
                      push: [ 0, 1 ]
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_TechnologyWall)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                (_ctx.cms.list)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.cms.list, (set, index) => {
                      return (_openBlock(), _createBlock(_component_TitleAndList, {
                        key: index,
                        title: set.label,
                        list: set.items,
                        class: "pt-16 md:pt-24",
                        stacked: ""
                      }, null, 8, ["title", "list"]))
                    }), 128))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_Container, {
              fluid: "",
              class: "mt-24 md:mt-48"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Grid, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_Column, {
                      span: [ 2, 4 ],
                      push: [ 0, 1 ]
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Heading, {
                          tag: "h2",
                          class: "overflow-hidden",
                          innerHTML: _ctx.cms.technology_heading_2
                        }, null, 8, ["innerHTML"]),
                        _createVNode(_component_Paragraph, {
                          class: "overflow-hidden text-gray-600",
                          innerHTML: _ctx.cms.technology_content_2
                        }, null, 8, ["innerHTML"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["to"])
      ]),
      _: 1
    }),
    _createVNode(_component_ThemeChanger, {
      to: _ctx.Theme.Dark
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Container, { fluid: "" }, {
          default: _withCtx(() => [
            (_ctx.cms?.related_cases_heading)
              ? (_openBlock(), _createBlock(_component_Grid, {
                  key: 0,
                  class: "mt-24 md:mt-48"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Column, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_Paragraph, {
                          innerHTML: _ctx.cms?.related_cases_heading,
                          heading: ""
                        }, null, 8, ["innerHTML"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.cms?.related_cases)
              ? (_openBlock(), _createBlock(_component_Projects, {
                  key: _ctx.cms?.heading,
                  class: "sm:mt-12 md:mt-16",
                  data: _ctx.cms?.related_cases
                }, null, 8, ["data"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["to"]),
    (_ctx.cms.seo)
      ? (_openBlock(), _createBlock(_component_MetaInfo, {
          key: 0,
          seo: _ctx.cms.seo,
          "structured-data": _ctx.cms.seo_structured_data
        }, null, 8, ["seo", "structured-data"]))
      : _createCommentVNode("", true)
  ], 64))
}